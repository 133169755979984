<template>
  <section class="products">
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="banner"
        :src="banner"
        class="ctk-top-banner"
        >
      </v-img>
    </v-flex>
    <v-container  grid-list-md text-center class="ctk-div"  id="ctk_top" style="padding-top:10px !important;">
      <v-layout align-center fill-height justify-center mb-4 mt-md-12 mb-md-12>
          <v-card flat class="ctk-submenu">
              <v-tabs grow light centered v-model="currentTab">
                <v-tab v-for="tab of categorys" :key="tab.key">
                  <a :href="'/products/'+tab.id">{{tab.name}}</a>
                </v-tab>
              </v-tabs>
          </v-card>
      </v-layout>
      <v-flex v-if="category" class="text-left" style="margin-bottom: 30px;">
        <span v-html="category.desc">{{category.desc}}</span>
      </v-flex>
      <v-layout wrap>
        <v-flex
          v-for="(product, i) in products"
          :key="i"
          xs12
          pa-2
          class="md3 ctk-jx-category"
        >
          <v-card
            class="mx-auto ctk-product-card"
          >
            <v-img
              :src="product.img"
              aspect-ratio="1"
              contain
              class="ctk-product-img"
            ></v-img>
          </v-card>
        </v-flex>
      </v-layout>
      <template>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="totalPage"
            circle
          ></v-pagination>
        </div>
      </template>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    category: { name: '', desc: '' },
    products: null,
    currentTab: 0,
    catIds: [],
    categorys: [],
    catId: null,
    pageId: 2,
    totalPage: 1,
    banner: null,
    page: 1
  }),
  created () {
    document.querySelector('#menu_products').classList.add('v-btn--active')
    this.catId = this.$route.params.cid
    this.getCategorys()
  },
  mounted () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.banner = val.banner[this.pageId][0].img
      },
      deep: true
    },
    page: {
      handler: function (val, oldVal) {
        document.querySelector('#ctk_top').scrollIntoView(true)
        this.getProducts(val, this.catId)
      },
      deep: true
    }
  },
  computed: {
  },
  methods: {
    getCategorys () {
      this.https.get('product_categorys').then(response => {
        if (response.code === 0) {
          this.categorys = response.data
          for (let i in this.categorys) {
            this.catIds.push(this.categorys[i].id)
          }
          if (!this.catId) {
            this.catId = this.catIds[0]
          }
          this.currentTab = this.catIds.indexOf(parseInt(this.catId))
          this.category = this.categorys[this.currentTab]
          this.getProducts(this.page, this.catId)
        }
      })
    },
    getProducts (page, cid) {
      this.https.get('products', { page: page, cid: cid, pageSize: 12 }).then(response => {
        if (response.code === 0) {
          this.products = response.data.data
          this.totalPage = response.data.last_page
          this.page = page
        }
      })
    }
  }
}
</script>
<style>

</style>
